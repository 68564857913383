<script>
import EnfantCard from '@/components/presentation/enfantCard.vue'
export default {
  name: 'enfants-index',
  components: {
    EnfantCard
  },
  data () {
    return {
    }
  },
  computed: {
    relations () {
      console.log(this.$store.state.User.relations)
      return this.$store.state.User.relations
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<template lang='pug'>
  .enfants-index
    .page-header(color='primary')
      .header__inner-content
        .header-title()
          //- div.secondary-title Sous-titre
          div.main-title Espace scolaire <br/> de {{relations.length > 1 ? 'mes enfants' : 'mon enfant'}}
        //- .header-description C'est avec des mots qu'on fait des phrases.
    .enfants-cards__wrapper
      template(v-if='relations.length > 0')
        enfant-card(v-for='(user, index) in relations', :key='index', :id='user.inferieur.v1Id', :user='user.inferieur')
</template>
<style lang='sass'>
@import 'src/styles/pages/_enfants'

</style>
